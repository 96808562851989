/* ----------------------------------------------
  Color Palette
---------------------------------------------- */
:root {
  --forestGreen: #243407;
  --rivianYellow: #DEB526;
  --offWhite: #FAFAFA;
  --darkText: #333333;
}

/* ----------------------------------------------
  Base & Body
---------------------------------------------- */
body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  background: var(--offWhite);
  color: var(--darkText);
}

/* ----------------------------------------------
  Header
---------------------------------------------- */
.header {
  background-color: var(--forestGreen); 
  padding: 2rem 1rem;
  text-align: center;
  color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.header h1 {
  margin: 0;
  font-size: 2.3rem;
  letter-spacing: 1px;
}

.header h2 {
  margin-top: 0.5rem;
  font-weight: normal;
  font-size: 1.2rem;
}

.header nav {
  margin-top: 1rem;
}

.header nav a {
  margin: 0 1rem;
  color: var(--rivianYellow);
  text-decoration: none;
  font-weight: 600;
  transition: color 0.2s ease;
}

.header nav a:hover {
  color: #ffe54e; /* slightly lighter yellow for hover */
}

/* ----------------------------------------------
  Section Containers
---------------------------------------------- */
section {
  max-width: 900px;
  margin: 2.5rem auto;
  padding: 2rem;
  background-color: #ffffff;
  border-left: 4px solid var(--forestGreen);
  border-right: 4px solid var(--forestGreen);
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.07);
}

section h2 {
  margin-top: 0;
  font-size: 1.8rem;
  color: var(--forestGreen);
  border-bottom: 2px solid var(--rivianYellow);
  display: inline-block;
  padding-bottom: 0.25rem;
}

section p {
  line-height: 1.6;
  margin-bottom: 1.2rem;
}

/* Experience Lists */
.experience ul {
  margin-left: 1.2rem;
  padding-left: 0;
}

.experience ul li {
  margin-bottom: 1.5rem;
}

.experience strong {
  color: var(--forestGreen);
}

/* ----------------------------------------------
  Footer
---------------------------------------------- */
.footer {
  background-color: var(--forestGreen); 
  color: #ffffff;
  text-align: center;
  padding: 2rem 1rem;
  margin-top: 3rem;
}

.footer p {
  margin: 0.5rem 0;
  font-weight: 500;
}

.footer ul {
  list-style-type: none;
  padding: 0;
  margin: 0.5rem 0;
}

.footer ul li {
  display: inline-block;
  margin: 0 1rem;
}

.footer a {
  color: var(--rivianYellow);
  text-decoration: none;
  font-weight: 600;
}

.footer a:hover {
  text-decoration: underline;
}